/* You can add global styles to this file, and also import other style files */
@forward 'constants';

/* Importing Bootstrap SCSS file. */
/* NB: If this ever gets appending by an npm update, it'll fuck your
* declared styling completely! */
/* Importing Bootstrap SCSS file. */
@use 'bootstrap/scss/bootstrap';
@use 'constants' as *;
@use 'texts' as *;

:root { color-scheme: light dark; /* both supported */ }

html, body { height: 100%; scroll-behavior: smooth;}
body { 
    margin: 0; 
    background-color: $colour_page_bg_lt;
    color: $colour_page_fg_lt;
}

nav {
    a, a:link, a:hover {
        cursor: pointer;
    }
}

.table {
    font-size: 1.1rem;
    th {
        font-size: 1.2rem;
    }
}

.pac-container {
    z-index: 1100 !important;
}

@media (prefers-color-scheme: dark) {
    body { 
        background-color: $colour_page_bg_dk;
        color: $colour_page_fg_dk;
    }    
    .table {
        color: rgb(238, 229, 229);
    }
    .table th, .table td {
        border-top: 1px solid #18191a;
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(39, 48, 59, 0.822);
        td {
            color: #fff;
        }
    }

    .slot-popover {    
        color: #fff !important;
        
        .popover-header {
            background-color: #b2b2b2 !important;
            color: #18191a !important;
        }
    }
}